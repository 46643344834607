import React from 'react'

import Box from '../../../components/Box/Box'
import styles from './About.module.css'

import jl from '../../../assets/images/jakob_laemmle_01.webp'
import pc from '../../../assets/images/pc_turbo_120Mhz_01.webp'
import stufenlinse from '../../../assets/images/Arri_Stufenlinse_01.webp'
import company from '../../../assets/images/lanyards_01.webp'
import zdh from '../../../assets/images/zdh_tent_01.webp'

import Image from '../../../components/Image/Image'
const About = () => {

    return(
        <Box>
        <h3>About</h3>
        <p>All right, something about me...</p>
        
        <div className={styles.Container}>
            <div align="left">
                Maybe you guessed it, I am kid of the '80s. I was born in 1983 in a small 
                city called Vaihingen/Enz near Stuttgart, Germany. I like to create new 
                things with technology. Whether it is a website, software, video, 3D 
                content, hardware, or a show event. I like it most when I can combine everything.
            </div>
            <Image src={jl} />
        </div>
        
        <div className={styles.Container}>
            <Image src={pc} />
            <div align="left">  
            I developed my passion for technology at an early age. At first, it was Märklin model trains, 
            then a PC 60 / 120Mhz with a turbo button and a 56K modem that immersed me in the world of
            technology.
            </div>
            
        </div>

        <div className={styles.Container}>
            <div  align="left">
                In school I joined the tech group for the theater lighting, I discovered the enthusiasm
                to work as a team to create shows.
            </div>
            <Image src={stufenlinse} />
        </div>

        <div className={styles.Container}>
            <Image src={company} />
            <div align="left">  
            In this group, I found new friends and we decided in the year of 2001 to grow up a new company 
            for light, sound, video, and web production. 
            </div>
            
        </div>
        
        <div className={styles.Container}>
       
            <div align="left">
            After years in the business with many concerts, events, exhibitions, and rock'n'roll, 
            in the year 2013, I also took part in the "Zirkus des Horrors" tour as a lighting operator. 
            
            Corona stopped the tour at the beginning of 2020. I decided to consolidate my 
            knowledge and learn new things to open up new perspectives.
            </div>
            <Image src={zdh} />
        </div>
        

        </Box>
    )
}

export default About