import React from 'react'
import styles from './Input.module.css'

const Input = (props) => {
    let inputElement = ''
    const inputClasses = [styles.Input]

    if(props.invalid && props.shouldValidate && props.touched){
        inputClasses.push(styles.Invalid)
    }

    switch (props.inputType) {
        case ('input'):
            inputElement = <input 
                onChange={props.changed}
                className={inputClasses.join(' ')} 
                {...props.elementConfig} 
                value={props.value}/>
            break
        case ('textarea'):
            inputElement = <textarea 
            onChange={props.changed}
            className={inputClasses.join(' ')} 
            {...props.elementConfig}
            value={props.value}/>
            break
        case ('select'):
            inputElement = (
                <select 
                onChange={props.changed}
                className={inputClasses.join(' ')}>
            { props.elementConfig.options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.displayValue}
                </option>
            ))}
                
            
            </select>
            )
            break
        case ('label'):
            inputElement = <div>{props.value}</div>
            break
        default:
            inputElement = <input 
            className={inputClasses.join(' ')} 
            {...props.elementConfig} 
            value={props.value}/>
    }
    return inputElement
}

export default Input
