import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRouteMatch } from "react-router-dom";
import { useRecoilState, useRecoilValue } from 'recoil'
import { visitorState } from '../../recoil/atoms/visitorAtom'
import { drinkState } from '../../recoil/atoms/drinkAtom'
import Box from '../../components/Box/Box'
import Button from '../../components/UI/Button/Button';
import Cup from '../../components/Cup/Cup'


const Serve = (props) => {
    const visitor = useRecoilValue(visitorState);
    const [drink, setDrink] = useRecoilState(drinkState);
    const match = useRouteMatch("/serve/:slug");

    let serve = ''
    try {
        serve = match.params.slug
    } catch (e) {
        
        serve = 'water'
    }

    useEffect(
        () => {
            setDrink({ level: 98, name: serve })
            
        },
        [serve, setDrink]
    )
    return (
        <div>
            <Box>

                Okay {visitor.name}, here's your {drink.name} with the cookies.<br />
                <Cup type={drink.name} />
            Take it!



            </Box>
            <Box>

                <Link to="/main"><Button clicked={() => { }}>Thank you</Button></Link>
                <Link to="/main/no-cookies"><Button>No Cookies please</Button></Link>
                <Link to="/"><Button>I changed my mind</Button></Link>
            </Box>

        </div>
    )
}


export default Serve