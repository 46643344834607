import React, {useEffect} from 'react'
import { Route, Switch, useRouteMatch, Link } from 'react-router-dom'
import Nav from '../../components/Nav/Nav'
import MainCup from '../../components/MainCup/MainCup'
import MainCookies from '../../components/MainCookies/MainCookies'
import Box from '../../components/Box/Box'
import SocialMediaButton from '../../components/SocialMediaButton/SocialMediaButton'
import Footer from '../../components/Footer/Footer'
import Modal from '../../components/UI/Modal/Modal'
import Button from '../../components/UI/Button/Button'

import About from '../../pages/Main/About/About'
import Work from '../../pages/Main/Work/Work'
import Cv from '../../pages/Main/Cv/Cv'
import Contact from '../../pages/Main/Contact/Contact'
import Imprint from '../../pages/Main/Imprint/Imprint'
import { useRecoilValue, useRecoilState } from 'recoil'
import { visitorState } from '../../recoil/atoms/visitorAtom'
import { drinkState } from '../../recoil/atoms/drinkAtom'
import { cookiesState } from '../../recoil/atoms/cookiesAtom'

const Main = () => {
    const visitor = useRecoilValue(visitorState)
    
    const [cookie, setCookie] = useRecoilState(cookiesState)

    const [drink, setDrink] = useRecoilState(drinkState)

    const match = useRouteMatch("/main/no-cookies");
    
    useEffect(() => {
        if(match === null){
        
        document.cookie = "digitalCookie1="+ Math.random();
        document.cookie = "digitalCookie2="+ Math.random();
        document.cookie = "digitalCookie3="+ Math.random();
        setCookie(1)
        }
    },[match, setCookie])

    
    
    const refillDrink = () => {
        setDrink({ level: 100, name: drink.name })
    }
 
    const refillDrinkNo = () => {
        setDrink({ level: 999, name: drink.name })
    }

    return (
        <div>
            <Nav />
            <Switch>
                <Route path="/main/about">
                    <About />
                </Route>

                <Route path="/main/work">
                    <Work />
                </Route>

                <Route path="/main/cv">
                    <Cv />
                </Route>

                <Route path="/main/contact">
                    <Contact />
                </Route>

                <Route path="/main/imprint">
                    <Imprint/>
            </Route>

                <Route path="/main">
                    <Box>
                        <h3>Aloha {visitor.name},</h3>
                        I'm glad you landed up in my corner of the ether space. <br />

                        Now that you are here, I'll show you what I like, snippets of my work and <br />
                        what I can do for you, besides making {drink.name}. <br />
                        <br />
                        Lean back, enjoy your {drink.name} {cookie ? 'with' : 'whitout'} cookies, and look around.  <br />
                        <br />
                        Ohh and <br />
                        remember<br/>
                        <br />
                        Don't<br />
                        PANIC!


                    </Box>
                </Route>

            </Switch>
            <Footer>
                
                <SocialMediaButton url="https://www.linkedin.com/in/jakob-laemmle" target="_blank" icon="fa-linkedin"/>
                <SocialMediaButton url="https://github.com/jaques30081983" target="_blank" icon="fa-github"/>
                <SocialMediaButton url="https://www.xing.com/profile/Jakob_Laemmle" target="_blank" icon="fa-xing"/>
                <SocialMediaButton url="https://www.instagram.com/jaqblacklamb" target="_blank" icon="fa-instagram"/>
                <SocialMediaButton url="https://stackoverflow.com/users/14665816" target="_blank" icon="fa-stack-overflow"/>
                
                <Link to="/main/imprint">Imprint</Link>
            </Footer>
            <MainCup />
            {cookie ? <MainCookies />  : ''}
            <Modal show={drink.level < 11 ? true : false }>
                <Box>
                Your {drink.name} is empty, want a refill ?<br/>
                <Button clicked={refillDrink}>Yes</Button> <Button clicked={refillDrinkNo}>No</Button>
                
                </Box>
            </Modal>
        </div>
    )
}


export default Main