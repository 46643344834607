import React from 'react'
import NavButton from '../NavButton/NavButton'
import styles from './Nav.module.css'
import { Link } from 'react-router-dom'
import Logo from '../Logo/Logo'
const Nav =(props) => (
    <div className={styles.Nav}>
    <Link to="/main/about" style={{order: 2}} className={styles.Flex}>
      <NavButton>About</NavButton>
    </Link>

    <Link to="/main/work" style={{order: 3}} className={styles.Flex}>
      <NavButton>Work</NavButton>
    </Link>

    <Link to="/main" className={styles.LogoOrder}>
    <Logo/>
    </Link>
    <Link to="/main/cv" style={{order: 5}} className={styles.Flex}>
      <NavButton>CV</NavButton>
    </Link>
    
    <Link to="/main/contact" style={{order: 6}} className={styles.Flex}>
      <NavButton>Contact</NavButton>
    </Link>
    
  </div>
)

export default Nav