import React from 'react'


import 'font-awesome/css/font-awesome.min.css';
import styles from './SocialMediaButton.module.css'

const SocialMediaButton = (props) => {

    return (
        <a 
            href={props.url} 
            target={props.target} 
            aria-label={props.name} 
            rel="noopener noreferrer"
            className={['fa', props.icon, styles.SocialMediaButton].join(' ')}>
        </a>
    )
}

export default SocialMediaButton