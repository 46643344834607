import React from 'react'


const Spinner = () => (
    <div >
    <svg width="32" height="32" viewBox="0 0 33.866666 33.866668" xmlns="http://www.w3.org/2000/svg">
      <g
        id="layer1"
        transform="translate(0,-263.13332)">
        <path
          d="m 13.050715,266.5651 v 22.03993 H 5.4406683 v -2.49509 H 0.53379534 v 2.66136 H 2.9455823 v 5.07347 H 15.670587 v -5.23974 h 2.578385 V 266.5651 Z"
          id="letterJ"
          />
        <path
          d="m 18.248972,266.5651 v 22.03993 5.23974 h 15.154481 v -5.23974 H 23.446906 V 266.5651 Z"
          id="letterL"
            />
        <rect
          id="dotOne"
          width="5.2010827"
          height="5.1811104"
          x="28.113541"
          y="277.75024" />
        <rect
          id="dotTwo"
          width="5.259634"
          height="5.1811104"
          x="28.142818"
          y="266.65482" />
      </g>
    </svg>
  </div>
)

export default Spinner