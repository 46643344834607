import React from 'react'
import { Link } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { visitorState } from '../../../recoil/atoms/visitorAtom'
import Box from '../../../components/Box/Box'
import styles from './Cv.module.css'
const Cv = () => {
const visitor = useRecoilValue(visitorState)
    
    if(visitor.name === 'Visitor'){
        return(
            <Box>
            <h3>Curriculum Vitae</h3>
    
            A tale about my journey through the ether space.<br/><br/>

            If you want to read more you need an invitation...<br/>

            You can <Link to="/main/contact">contact</Link> me and ask for it.
            </Box>
        )
    }else{

        return(
            <Box>
            <h3>Curriculum Vitae</h3>
    
            A tale about my journey through the ether space.<br/>
            
            
     
     <div className={styles.Content}>
    
        Jakob Lämmle<br/>
            
        Anschrift: Weingasse 2, 71665 Vaihingen an der Enz<br/>
            
        E-Mail: info@jakoblaemmle.de<br/>
            
        Telefon: +49 (0) 176/23108507<br/>
            
        Hello World: 1983-08-30<br/><br/>
            
        
        GitHub: <a href="https://github.com/jaques30081983"  rel="noopener noreferrer" target="_blank">jaques30081983</a> <br/>    
        LinkedIn: <a href="https://www.linkedin.com/in/jakob-laemmle" rel="noopener noreferrer" target="_blank" >Jakob-Laemmle</a> <br/>
        Stackoverflow: <a href="https://stackoverflow.com/users/14665816" rel="noopener noreferrer" target="_blank">jakob-lämmle</a><br/>
        XING: <a href="https://www.xing.com/profile/Jakob_Laemmle" rel="noopener noreferrer" target="_blank">Jakob_Laemmle</a><br/>
              
     </div>
            
         <h4>My Profile</h4>   
        <div className={styles.Content}>
        - Developer, Light Operator, Allrounder<br/>
        - passionate in technology, Autodidakt<br/>
        - Rock‘n‘Roll proofed!  <br/><br/>
        </div>
             
    
            <h4>WORK EXPERIENCE</h4>
            <div className={styles.Content}>
            <h5>2020-03 – Nowadays</h5>
            Laiseacker GmbH<br/>
            - Delivery of organic vegetables and natural food<br/><br/>

            <h5>2010-01 – Nowadays</h5>
             
            bigwood, Lämmle & Heinrich GbR<br/>
             
            Medien- & Veranstaltungstechnik<br/>
             
            Verleih, Verkauf, Installation und Service<br/>
             
            von Licht-, Ton-, Medien-, und Videotechnik<br/>
             
             
            - Lightdesign<br/>
             
            - Shareholder and CEO<br/>
             
            - Accounting and finance<br/>
             
            - Development “bozwo“ ERP/CRM in PHP (Laravel), JS (OpenUi5)<br/><br/>
             
             
            Tour “Zirkus des Horrors“ 2013 – 2020,<br/>
             
            - Individual implementation, Fan-Shop<br/>
             
            - Creation of Website in PHP (concrete5)<br/>
             
            - Development “btms“ Ticketing in Python
             
            with kivy, crossbar, autobahn, twisted,
             
            mysqldb and cups.<br/><br/>
             
            - Lighting technology planning and implementation<br/>
             
            - Development “ArtNet Node“ für blender 3d<br/>
             
            - Creation of 3d Content for Show projection<br/>
             
            - Development and Manufacturing of intelligent
             
            props with mikrocontrollers in C++<br/>
             
            - Dramaturgy and direction<br/>
             
            - Maintance and repair of equipment<br/><br/>
            </div>
             
            <div className={styles.Content}><h5>2003-02 – 2004-05</h5>
             
            One-man business bigwood-multimedia<br/>
             
            - Webdesign, Webhosting, PC-repair<br/>
             
            - Creation of websites in HTML / PHP / FLASH<br/>
             
            - Development CMS “bws“ in HTML / JS / PHP<br/>
             </div>
            
             <div className={styles.Content}><h5>2002-09 – 2009-12</h5>
             
            bigwood Medien- & Veranstaltungstechnik,<br/>
             
            Lämmle & Heinrich & Franz GbR
             
            - Webdesign, Hosting, Videoproduction,<br/>
             
            Event-Service, Light & Sound rental
             
            - Lightdesign<br/>
             
            - Shareholder and CEO<br/>
             
            - Accounting and finance<br/>
             
            - Creation of Websites in HTML / PHP / FLASH<br/>
             
            - Devekopment ERP/CRM “bo“ in HTML / PHP<br/>
            </div>
    
    
           
            <h4>EDUCATION</h4>
             
            <div className={styles.Content}>
            <h5>2020-01</h5>
            - <a href="https://www.udemy.com/certificate/UC-d61e5277-9d6b-49e6-80c1-a8dd50972d7d/"  rel="noopener noreferrer" target="_blank">Understanding Jira for users, managers and admins</a><br/>
            <h5>2020-07 – 2020-11</h5>
            - <a href="https://www.udemy.com/certificate/UC-6656056e-adba-4936-ba7a-fc7e179ffbf0/"  rel="noopener noreferrer" target="_blank">React - The Complete Guide (incl Hooks, React Router, Redux)</a> <br/>
            <h5>2020-03 – 2020-06</h5>
            - <a href="https://www.udemy.com/certificate/UC-cbd66486-820a-499e-9755-a5306dceb1a2/"  rel="noopener noreferrer" target="_blank">The Modern JavaScript Bootcamp</a> <br/>
            <h5>1990-09 – 2002-06</h5>
            Intermediate school certificate <br/>
             
            - Freie Waldorfschule Vaihingen an der Enz<br/>
             </div>
            <h4>PRACTICAL EXPERIENCE </h4>
            <div className={styles.Content}>
            <h5>2002-12 – 2003-08</h5>
             
            Kindergarten pre-study internship<br/>
             
            - Evangelischer Kindergarten Vai-Kleinglattbach<br/><br/>
             
             
            <h5>2001-05 – 2001-06</h5>
             
            Internship<br/>
             
            - PCB manufacturer STP Sindelfingen, It department<br/><br/>
             
             
            <h5>2000-10 – 2000-10</h5>
             
            Social internship<br/>
             
            - Waldorf Kindergarten Vaihingen an der Enz<br/>
             </div> 
            
            <h4>SOCIAL COMMITMENT</h4>
            
            <div className={styles.Content}>
             <h5>2005-11 – 2006-07</h5>
             
             Community service<br/>
             
            - Pflege Service Holzwart, Eberdingen,
             
            Individual care for the severely disabled
            </div> 
            
            <h4>FURTHER SKILLS</h4>
            <div className={styles.Content}>
            <h5>Languages</h5>
             
            - German - native<br/>
             
            - English - good knowledge<br/>
             
            - Hungarian - basic knowledge<br/>
             
            - Spanish - basic knowledge<br/>
            </div>  
            <div className={styles.Content}>
            <h5>Software</h5>
             
            - Linux, VS Code, Jira, Github, concrete5, blender,
             
            kdenlive, krita, inkscape, etc...
            </div>
            
            <div className={styles.Content}>
            <h5>Programming languages and frameworks</h5>
             
            - Python (kivy, crossbar, twisted) <br/>
             
            - Php (laravel, lumen)<br/>
             
            - Javascript (reactJS / (Native in prog.), openui5)<br/>
             
            - C++ (Arduino, ESP8266, ESP32)<br/>
                          
            - MySql, SQLite, Firebase<br/>
             
            </div>
    
            <h4>INTERESTS</h4>
             <div className={styles.Content}>
             Programming, electronics, video editing,
             
            blender 3d, Light & sound technology,
             
            gastronomy coffee machines, Aikido, Music, Travel
             </div>
            
            </Box>
        )

    }

    
}

export default Cv