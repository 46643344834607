import React from 'react'

import Box from '../../../components/Box/Box'
const Imprint = () => {

    return(
        <Box>
        <h3>Imprint</h3>
        <h5>Angaben gemäß § 5 TMG:</h5>
        <p>Jakob Lämmle</p>

        <h5>Postanschrift:</h5>
        <p>Weingasse 2<br/>71665 Vaihingen an der Enz<br/></p>

        <h5>Kontakt:</h5>
        <p>E-Mail: info [ @ ] jakoblaemmle.de</p>
        

        </Box>
    )
}

export default Imprint