import { selectorFamily } from 'recoil'
import axios from 'axios'

export const visitorQueryByToken = selectorFamily({
    key: 'getVisitorById',
    get: token => async () => {
        try {
            const URL = 'https://jakoblaemmle.de/jl-api/public/api/v1/visitor/token/'+token
            const res = await axios({
                url: URL,
                method: 'get',
            })

            return res.data

        } catch (err) {
            console.log('itemsSelectors.js :: ERRORS')
            console.warn(err)
            return `Error: ${err}`
        }
    },
})
