import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { RecoilRoot } from 'recoil';

import styles from './App.module.css'

import Welcome from './pages/Welcome/Welcome'
import Serve from './pages/Serve/Serve'
import Main from './pages/Main/Main'

const app = () => {
    
    return (
    <div className={styles.App}>
      <RecoilRoot>
        <Router>
          <Switch>
            <Route path="/serve/:slug">
              <Serve />
            </Route>

            <Route path="/invitation/:slug">
              <Welcome />
            </Route>

            <Route path="/main"> 
              <Main />
            </Route>

            <Route path="/">
              <Welcome />
            </Route>



          </Switch>
        </Router>
        <div className={styles.Scanline}></div>
      </RecoilRoot>
    </div>
    );
  
}

export default app;
