import React from 'react'

import Box from '../../../components/Box/Box'
import Image from '../../../components/Image/Image'
import styles from './Work.module.css'

import zdhWeb from '../../../assets/images/zdh_web_01.webp'
import zdhLight from '../../../assets/images/zdh_light_01.webp'
import DMXmemory from '../../../assets/images/DMXmemory_01.webp'
import zdhVideo from '../../../assets/images/zdh_video_01.webp'
import zdh3d from '../../../assets/images/zdh_3d_01.webp'
const Work = () => {

    return(
        <Box>
        <h3>Work</h3>

        Here is a selection of my work that I have done in the past.<br/><br/>
        
        
        <h4>- Web -</h4>
            <div className={styles.Container}>
                <div>
                    <ul>
                        <li>
                        <a href="http://zirkusdeshorrors.de" rel="noopener noreferrer" target="_blank">zirkusdeshorrors.de</a> 
                        (Concrete5)
                        </li>
                
                        <li>
                        <a href="http://meinweihnachtsmarkt24.de" rel="noopener noreferrer" target="_blank">meinweihnachtsmarkt24.de</a> 
                        (PrestaShop)
                        </li>

                        <li>
                        <a href="http://kwaku.de" rel="noopener noreferrer" target="_blank">kwaku.de</a> 
                        (Concrete5)
                        </li>

                        <li>
                        <a href="http://jakoblaemmle.de" rel="noopener noreferrer" target="_blank">jakoblaemmle.de</a> 
                        (React, Lumen)
                        </li>
                    </ul>
                </div>
                <Image src={zdhWeb} />
            </div>
       
        <h4>- Light -</h4>
        <div className={styles.Container}>
            <ul>
                <li>Zirkus des Horrors</li>
                <li>Potatoes - Band</li>
                <li>Karlsruher Weihnachtscircus</li>
                <li>Modern Church Band</li>
                <li>Lions Comedy Night</li>
                <li>Bloody Onion - Band</li>
                <li>Champions-Night Box-Event Karlsruhe</li>
                <li>Trierer Weihnachtscircus</li>

            </ul>
            <Image src={zdhLight} />
            </div>

        <h4>- Apps / Software / Hardware -</h4>
            <div className={styles.Container}>
            
            <ul>
                <li><a href="https://github.com/jaques30081983/bozwo" rel="noopener noreferrer" target="_blank" alt="bozwo">
                bozwo - erp crm system (Javascript OpenUi5, PHP Laravel)</a></li>
                <li><a href="https://github.com/jaques30081983/btms_suite" rel="noopener noreferrer" target="_blank" alt="bozwo">
                BTMS - Suite - Just Tickets (Kiviy, Crossbar, Python)</a></li>
                
                <li><a href="https://github.com/jaques30081983/raijin" rel="noopener noreferrer" target="_blank" alt="bozwo">
                raijin - lightning cloud system (ESP C++, Kivy Python)</a></li>

                <li>DMXmemory - A radio like DMX recorder with 3x memory buttons (ESP C++)</li>
                <li>Blender Art-Net Node Player plugin (Blender Python)</li>
                <li>Gaston - Talking microphone robot (ESP C++)</li>
            </ul>
            <Image src={DMXmemory} />
            </div>
        <h4>- Video -</h4>
        <div className={styles.Container}>
            <ul>
                <li><a href="https://www.youtube.com/channel/UCyqkMpW6LTiI8VnGKW8669g" rel="noopener noreferrer" target="_blank">
                Streaming - Karlsruher Weihnachtscircus</a></li>
                <li><a href="https://www.youtube.com/watch?v=Dxj-qxGb890" rel="noopener noreferrer" target="_blank">
                Impressionen 2020 - Zirkus des Horrors</a></li>
                
                <li><a href="https://www.youtube.com/watch?v=H6AVLZ78t9w" rel="noopener noreferrer" target="_blank">
                WIR KOMMEN WIEDER! - Zirkus des Horrors</a></li>

                <li><a href="https://www.youtube.com/watch?v=wMk5cYSnOyc" rel="noopener noreferrer" target="_blank">
                INFERNUM - Das Höllenfeuer - Trailer Saarbrücken 2020 - Zirkus des Horrors</a></li>
                

                <li>SOFA - live video editing</li>

            </ul>
            <Image src={zdhVideo} />
            </div>

        <h4>- 3d -</h4>
            <div className={styles.Container}>
        
            <ul>
            <li>Live 3d mapped on stage - Zirkus des Horrors (Blender, Python, Art-Net, OpenCV)</li>
            <li>Content for Zirkus des Horrors</li>
            <li>Content for Karlsruher Weihnachtscircus</li>
            </ul>
            <Image src={zdh3d} />
            </div>
            <h4>And much more...</h4>
        </Box>
    )
}

export default Work