import React from 'react'
import styles from './Cup.module.css'

import coffeeCup from '../../assets/images/coffee_cup_01.webp'
import coffeeCupLevel from '../../assets/images/coffee_cup_level_01.webp'

import teaCup from '../../assets/images/tea_cup_01.webp'
import teaCupLevel from '../../assets/images/tea_cup_level_01.webp'
const Cup = (props) => {

    return(
        <div className={styles.CupDiv}>
        
        <span className={styles.steam} />

        <img alt="Cup" src={props.type === 'coffee'? coffeeCup : teaCup} className={styles.Cup}/>
        <img alt="Level" src={props.type === 'coffee'? coffeeCupLevel : teaCupLevel} className={styles.Level} />
        </div>
    )

}


export default Cup