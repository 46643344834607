import React from 'react'
import styles from './Image.module.css'
const Image = (props) => {
    
    return(
        
        <img src={props.src} alt={props.alt} className={[styles.Image, styles[props.Float]].join(' ')}  />
        
    )
}

export default Image