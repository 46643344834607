import React from 'react'
import LogoSVG from '../../assets/images/logo_01.svg'
import styles from './Logo.module.css'
import useSound from 'use-sound';
import logoSfx from '../../assets/sounds/logo.mp3';
const Logo = () => {
    
        const [play, { stop }] = useSound(
            logoSfx,
        { volume: 0.5 }
      );
    
   
    return(
        <div className={styles.LogoDiv}
        onMouseEnter={() => {
            play();
          }}
          onMouseLeave={() => {
            stop();
          }}
        >
        <img className={styles.Logo} alt="JL" src={LogoSVG} />
        </div>
    )
}

export default Logo