import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import axios from 'axios'
import Box from '../../../components/Box/Box'
import Button from '../../../components/UI/Button/Button'

import Input from '../../../components/UI/Input/Input'

import Spinner from '../../../components/UI/Spinner/Spinner'
import { updateObject, checkValidity } from '../../../shared/utility'
import { visitorState } from '../../../recoil/atoms/visitorAtom'

const stateForm = {
    contactForm: {
        name: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Your Name'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },

        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                placeholder: 'Your E-Mail'
            },
            value: '',
            validation: {
                required: true,
                isEmail: true
            },
            valid: false,
            touched: false
        },

        message: {
            elementType: 'textarea',
            elementConfig: {
                type: 'text',
                placeholder: 'Your Message'
            },
            value: '',
            validation: {
                required: true,
                minLength: 10


            },
            valid: false,
            touched: false
        },
        label: {
            elementType: 'label',
            elementConfig: {
                type: 'text',
                placeholder: 'Answer'
            },
            value: 'What is the Answer to the Ultimate Question of Life, the Universe, and Everything?',
            valid: true,
            touched: false
        },
        captcha: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Answer'
            },
            value: '',
            validation: {
                required: true,
                minLength: 2

            },
            valid: false,
            touched: false
        }
    },
    formIsValid: false,


}



const statusState = {
    loading: false,
    sent: false,
    error: ''
}





const Contact = () => {
    const [state, setState] = useState(stateForm)
    const [status, setStatus] = useState(statusState)
    const visitor = useRecoilValue(visitorState)

    useEffect(()=>{
        if(visitor.name !== 'Visitor'){
            setState(prevState => ({
                contactForm:{
                    ...prevState.contactForm,
                    name:{
                        ...prevState.contactForm.name,
                        value: visitor.name,
                        valid: SVGComponentTransferFunctionElement
                    },
                    email:{
                        ...prevState.contactForm.email,
                        value: visitor.email,
                        valid:true
                    }
                }
            }))
        }

           
            
    }, [visitor])


    const inputChangedHandler = (event, inputIdentifier) => {
        console.log('input changed')
        const updatedFormElement = updateObject(state.contactForm[inputIdentifier], {
            value: event.target.value,
            valid: checkValidity(event.target.value, state.contactForm[inputIdentifier].validation),
            touched: true
        })

        const updatedOrderForm = updateObject(state.contactForm, {
            [inputIdentifier]: updatedFormElement
        })


        let formIsValid = true
        for (let inputIdentifier in updatedOrderForm) {
            formIsValid = updatedOrderForm[inputIdentifier].valid && formIsValid
        }

        setState({ contactForm: updatedOrderForm, formIsValid: formIsValid })


    }



    const messageSendHandler = (event) => {
        

        setStatus({ loading: true, sent: false, error: '' })
        event.preventDefault()

        const formData = {}
        for (let formElementIdentifier in state.contactForm) {
            formData[formElementIdentifier] = state.contactForm[formElementIdentifier].value
        }


 
        
        
        axios.post('https://jakoblaemmle.de/jl-api/public/api/v1/contact', formData)
        .then(response => {
            setStatus({ loading: false, sent: true, error: '' })

            if(response.data.status === 0){
                setStatus({ loading: false, sent: false, error: 'The Answer was wrong! Try again.' })
            }

        })
        .catch(error => {
            setStatus({ loading: false, error: error.message })
        })
        
    }




    const formElementsArray = []
    for (let key in state.contactForm) {
        formElementsArray.push({
            id: key,
            config: state.contactForm[key]
        })
    }
    

    let form = (
        <form onSubmit={messageSendHandler}>


            {
                formElementsArray.map(formElement => (
                    <Input
                        changed={(event) => inputChangedHandler(event, formElement.id)}
                        key={formElement.id}
                        inputType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        touched={formElement.config.touched}
                        shouldValidate={formElement.config.validation}
                        invalid={!formElement.config.valid} />
                ))

            }
            {status.loading ? <Spinner /> : <Button btnType="Success" disabled={!state.formIsValid}>Send Message</Button> }
            
            
        </form>
    );
    




    return (
        <Box>
            <h3>Contact</h3>
        If everything is wired correctly,
        it might be possible to contact me.
        {form}

        {status.sent ? 'Message was sent. Thank you!' : ''}
        {status.error ? status.error : ''}
        </Box>
    )
}

export default Contact