import React from 'react'
import styles from './MainCookies.module.css'
import cookieImage from '../../assets/images/cookie_01.webp'

const Cookies = (props) => {

    return (
        <div className={styles.CookiesDiv}>
        <img alt="Cookie" src={cookieImage}  className={[styles.Cookies, styles.Cookie1].join(' ')} />
        <img alt="Cookie" src={cookieImage}  className={[styles.Cookies, styles.Cookie2].join(' ')} />
        <img alt="Cookie" src={cookieImage}  className={[styles.Cookies, styles.Cookie3].join(' ')} />
        </div>
    )

}


export default Cookies