import React, { useEffect } from 'react'
import styles from './MainCup.module.css'
import teaCup from '../../assets/images/main_tea_cup_01.webp'
import teaCupLevel from '../../assets/images/tea_cup_level_01.webp'
import coffeeCup from '../../assets/images/main_coffee_cup_01.webp'
import coffeeCupLevel from '../../assets/images/coffee_cup_level_01.webp'
import { useRecoilState } from 'recoil'
import { drinkState } from '../../recoil/atoms/drinkAtom'

import useSound from 'use-sound';
import refillSfx from '../../assets/sounds/refill.mp3';

const Cup = (props) => {
    const [drink, setDrink] = useRecoilState(drinkState)

    const [play] = useSound(
        refillSfx,
        { volume: 0.5 }
    );

    useEffect(() => {
        const interval = setInterval(() => {

            setDrink({ level: drink.level - 1, name: drink.name })
            if (drink.level < 1) {
                setDrink({ level: 10, name: drink.name })
            }

            if (drink.level > 99 && drink.level <= 100) {
                play();
            }



        }, 3000);

        if (drink.level > 990) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [drink, play, setDrink]);


    return (
        <div className={styles.CupDiv}>

            <span className={styles.steam} />
            <img alt="Cup" src={drink.name === 'coffee' ? coffeeCup : teaCup} className={styles.Cup} />
            <img alt="Level" src={drink.name === 'coffee' ? coffeeCupLevel : teaCupLevel}
                style={{ bottom: `${(drink.level / 10) + 5}vw` }}
                className={styles.Level} />
        </div>
    )

}


export default Cup